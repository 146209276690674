.h-76{
    height: 76px;
}
.h-61{
    height: 61px;
}
.h-32-ten{
    height: 32px !important;
}
.h-76-ten{
    height: 56px !important;
}
.h-61-ten{
    height: 61px !important;
}
.lineclamp2{
    -webkit-line-clamp:2 !important;
}
.pstyle10 .box{
    margin-bottom: 2px !important;
}