.modelWidth {
    width: 90% !important;
    max-width: 90% !important;
}
.p-name-div {
    border: 1px solid #dadada;
    height: 185px;
    overflow-y: auto;
}
.sub-head {
    background: #8f9090;
    margin-bottom: 0;
    padding: 8px 18px;
    font-size: 17px;
    color: #fff;
	text-align: center;
}
h5.sub-sub-head {
    font-size: 15px;
    padding: 6px 16px;
    margin-bottom: 0;
    margin-top: 8px;
}
.capability-list {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.17px;
    color: rgb(51, 51, 51);
    box-shadow: rgb(0 0 0 / 50%) 0px 2px 4px 0px;
    padding: 7px 3px;
    margin: 7px 15px 7px 15px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
}
span.capability-list{
	margin: 0;
    display: inline-block;
    width: 75%;
}
h3.main-project-name {
	font-size: 19px;
    margin-bottom: 0px;
    line-height: 39px;
}
h5.sub-sub-head {
	font-size: 15px;
    padding: 10px 16px;
    margin-bottom: 9px;
    margin-top: 15px;
    text-align: center;
    background: #fff48f;

    margin: 1px 0px 0 0px;
}
.main-project-nameselect{
	font-size: 13px;
    height: 40px;
    font-stretch: normal;
    line-height: 1.85;
    letter-spacing: 0.12px;
    color: rgba(0, 0, 0, 0.6);
	border: 1px solid #ced4da;
    width: 100%;
	margin-left: 0px;
	margin-bottom:15px;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.box-dependency{
	border: 1px solid #dadada;
	margin-bottom: 20px;
	margin-top: 10px;
}
.de-indecapa{
	padding: 7px 10px;
}
.de-indecapa p{
	font-size: 14px;
	color: #858585;
	margin-bottom: 6px;
	margin-left: 0;
	margin-right: 0;
	font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.17px;
    color: rgb(51, 51, 51)
}
.de-indecapa h5 {
    margin-bottom: 18px;
    font-size: 16px;
    color: #767676;
}
.flo-depen-link{
	float: right;
	margin-top: -5px;
}
input.depend-value {
    width: 50px;
    height: 30px;
    margin-left: 8px;
    border: 1px solid #ced4da;
}
.de-indecapa a{
	float: right;
    font-size: 14px;
    text-decoration: none !important;
}
.trash-main{
    float: right;
    color: white;
    background: #e76c37;
    padding: 5px 6px;
    border-radius: 3px;
    font-size: 17px;
    cursor: pointer;
}
.trash-main-inner {
    font-size: 16px !important;
    float: right;
    color: #e76c37;
    margin-top: 5px;
    cursor: pointer;
}
.dep-cap{
    display: inline-block;
    width:90%;
    margin: 0;
    vertical-align: top;
}


.main-div-project{
    height: 500px;
    overflow-y: auto;
}