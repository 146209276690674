.pstyle16 .h-41s3 {
    height: 29px!important;
}
.pstyle16 .h-49s3{
    height: 39px!important;
}
.rotate-textbox-Style16, .rotate-textbox-Style110{
    transform: rotate(-90deg) !important;
    position: absolute !important;
    right: 0 !important;
    transform: translate(90deg) !important;
    left: -83px !important;
    bottom: 100px !important;
    width: 195px;
}