    /* Aside Menu */
    
    /*.aside-menu.aside-menu-right {
        z-index: 1019;
        width: 250px;
        color: #2f353a;
        background: #fff;
        border-left: 1px solid #c8ced3;
        display: none;
    }*/
    .aside-menu.aside-menu-right {
        z-index: 9;
    }
    
    .aside-menu.aside-menu-right .nav-tabs {
        border-color: #c8ced3;
    }
    .questionaireRightBar{
        width: 70px;
        text-align: center;
        padding: 14px 18px;
        background-color: #d8d8d8;
        color: #708393;
        height: 50px;
      }
    .arrowBtn{
        display: none;
      }
    
    .aside-menu.aside-menu-right .nav-tabs .nav-link {
        padding: 0.75rem 1rem;
        color: #23282c;
        border-top: 0;
        border-radius: 0;
    }
    
    .aside-menu.aside-menu-right .nav-tabs .nav-link.active {
        color: #797c7d;
        border-right-color: #c8ced3;
        border-left-color: #c8ced3;
        background-color: #c8ced3;
    }
    
    .aside-menu.aside-menu-right .nav-tabs .nav-item:first-child .nav-link {
        border-left: 0;
    }

    .aside-menu.aside-menu-right .tab-content {
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        /* border: 0; */
        border-top: 1px solid #c8ced3;
        height: 100vh;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .aside-menu.aside-menu-right .tab-content.toggled {
        display: none;
    }
    
    .aside-menu.aside-menu-right .tab-content::-webkit-scrollbar {
        width: 10px;
        margin-left: -10px;
        -webkit-appearance: none;
        appearance: none;
    }
    
    .aside-menu.aside-menu-right .tab-content::-webkit-scrollbar-track {
        background-color: white;
        border-right: 1px solid #f2f2f2;
        border-left: 1px solid #f2f2f2;
    }
    
    .aside-menu.aside-menu-right .tab-content::-webkit-scrollbar-thumb {
        height: 50px;
        background-color: #e6e6e6;
        background-clip: content-box;
        border-color: transparent;
        border-style: solid;
        border-width: 1px 2px;
    }
    
    .aside-menu.aside-menu-right .tab-content .tab-pane {
        padding: 0;
    }
    
    .app-body .aside-menu.aside-menu-right {
        -ms-flex: 0 0 250px;
        flex: 0 0 250px;
    }
    
    .aside-menu.aside-menu-right .avatar {
        position: relative;
        display: inline-block;
        width: 36px;
        height: 36px;
    }
    
    .aside-menu.aside-menu-right .img-avatar {
        max-width: 37px;
        height: 36px;
        border-radius: 100em;
        object-fit: cover;
    }
    
    .aside-menu.aside-menu-right .avatar .avatar-status {
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 10px;
        height: 10px;
        border: 1px solid #fff;
        border-radius: 50em;
    }
    
    /*.aside-menu.aside-menu-right.toggled {
        right: 0;
        top: 47px;
        margin-right: 0;
        width: 250px;
        height: 100%;
        position: fixed;
        z-index: 1;
        display: block;
    }*/
    
    html[dir="rtl"] .aside-menu.aside-menu-right {
        left: 0;
        margin-left: -250px;
    }
    
    @media (min-width: 992px) {
        .aside-menu.aside-menu-right-fixed .app-header+.app-body .aside-menu.aside-menu-right {
            height: calc(100vh - 55px);
        }
        .aside-menu.aside-menu-right-off-canvas .aside-menu.aside-menu-right {
            position: fixed;
            z-index: 1019;
            height: 100%;
        }
        .aside-menu.aside-menu-right-off-canvas .app-header+.app-body .aside-menu.aside-menu-right {
            height: calc(100vh - 55px);
        }
        html:not([dir="rtl"]) .aside-menu.aside-menu-right-fixed .aside-menu.aside-menu-right,
        html:not([dir="rtl"]) .aside-menu.aside-menu-right-off-canvas .aside-menu.aside-menu-right {
            right: 0;
        }
        html[dir="rtl"] .aside-menu.aside-menu-right-fixed .aside-menu.aside-menu-right,
        html[dir="rtl"] .aside-menu.aside-menu-right-off-canvas .aside-menu.aside-menu-right {
            left: 0;
        }
    }
    
    html:not([dir="rtl"]) .aside-menu.aside-menu-right-show .aside-menu.aside-menu-right,
    html:not([dir="rtl"]) .aside-menu.aside-menu-right-show .aside-menu.aside-menu-right {
        margin-right: 0;
    }
    
    html[dir="rtl"] .aside-menu.aside-menu-right-show .aside-menu.aside-menu-right,
    html[dir="rtl"] .aside-menu.aside-menu-right-show .aside-menu.aside-menu-right {
        margin-left: 0;
    }
    
    @-webkit-keyframes opacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    
    @keyframes opacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    
    @media (max-width: 575.98px) {
        .aside-menu.aside-menu-right-show .main {
            position: relative;
        }
        .aside-menu.aside-menu-right-show .main::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1018;
            width: 100%;
            height: 100%;
            content: "";
            background: rgba(0, 0, 0, 0.7);
            -webkit-animation: opacity 0.25s;
            animation: opacity 0.25s;
        }
    }
    
    @media (min-width: 576px) {
        html:not([dir="rtl"]) .aside-menu.aside-menu-right-show .aside-menu.aside-menu-right,
        html:not([dir="rtl"]) .aside-menu.aside-menu-right-sm-show .aside-menu.aside-menu-right {
            margin-right: 0;
        }
        @media (min-width: 576px) {
            html[dir="rtl"] .aside-menu.aside-menu-right-show .aside-menu.aside-menu-right,
            html[dir="rtl"] .aside-menu.aside-menu-right-sm-show .aside-menu.aside-menu-right {
                margin-left: 0;
            }
        }
        @media (min-width: 768px) {
            html[dir="rtl"] .aside-menu.aside-menu-right-show .aside-menu.aside-menu-right,
            html[dir="rtl"] .aside-menu.aside-menu-right-md-show .aside-menu.aside-menu-right {
                margin-left: 0;
            }
        }
        @media (min-width: 992px) {
            html:not([dir="rtl"]) .aside-menu.aside-menu-right-show .aside-menu.aside-menu-right,
            html:not([dir="rtl"]) .aside-menu.aside-menu-right-lg-show .aside-menu.aside-menu-right {
                margin-right: 0;
            }
        }
        @media (min-width: 992px) {
            html[dir="rtl"] .aside-menu.aside-menu-right-show .aside-menu.aside-menu-right,
            html[dir="rtl"] .aside-menu.aside-menu-right-lg-show .aside-menu.aside-menu-right {
                margin-left: 0;
            }
        }
        @media (min-width: 1200px) {
            html[dir="rtl"] .aside-menu.aside-menu-right-show .aside-menu.aside-menu-right,
            html[dir="rtl"] .aside-menu.aside-menu-right-xl-show .aside-menu.aside-menu-right {
                margin-left: 0;
            }
        }
        .aside-menu.aside-menu-right {
            transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
            transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s, -ms-flex 0.25s;
        }
    }