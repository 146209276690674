/* Footer css*/

footer .copyright {
    font-size: 13px;
    color: #708393;
}
footer.dashfooter{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
}
body{
    margin-bottom: 0 !important;
}
#wrapper {
    margin-bottom: 60px;
}
.footer-logo{
    width: 55px;
    margin-left: 3px;
}