.h-49s3{
    height: 49px;;
} 
.pstyle14 .h-49s3 {
    height: 50px;
}
 .w-150px{
     width:150px !important;
 }
 
.h-68s3{
    height: 68px;
} 
.vtext-temp3{
    position: absolute;
    top: 8px;
    height: 30px;
    width: 210px !important;
    margin: 0;
    left: 25px;
    transform-origin: 0 0;
    transform: rotate(90deg);
}
.box-white-3 {
    position: relative;
    height: 223px !important;
    width: 30px;
    margin-bottom: 0 !important;
}
.fl-sl3{
    position: absolute;
    position: absolute;
    right: 6px;
    bottom: 5px;
    z-index: 9;
}
.rotate-textbox-style3{
        transform: rotate(-90deg) !important;
        position: absolute !important;
        right: 0 !important;
        transform: translate(90deg) !important;
        left: -82px !important;
        bottom: 98px !important;
        width: 193px;
}
.threewidthimp{
    width: 70px !important
}
.lineclamp2{
    -webkit-line-clamp:2 !important;
}
.rotate-textbox-Style14{
    transform: rotate(-90deg) !important;
    position: absolute !important;
    right: 0 !important;
    transform: translate(90deg) !important;
    left: -83px !important;
    bottom: 100px !important;
    width: 195px;
}