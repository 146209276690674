.capabilityMappingWrapper{
    margin-left: 15px;
}
.capability-mapping-section{
    width:95% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.model-label{
    font-weight: bold;
    font-size: 14px;
}
.domain-name-section{
    width:100% !important;
    display: flex;
    align-items: start;
    justify-content: space-around;
    margin-top: 20px;
}
.save-progress-panel{
    justify-content: space-around;
    align-items: flex-end;
    margin-bottom: 20px;
}
.inner-width{
    width:85% !important;
}
.inner-section{
    width:30% !important;
}
.domain-mapping-panel{
    width:70% !important;
}
.domain-mapping-section{
    width:100% !important;
    height:500px;
    overflow: auto;
    margin-top: 20px;
}
.versionWrapper{
    width:95% !important;
    padding-top: 10px;
    height:410px;
    overflow: auto;
    border: 1px #ccc solid;
}
.domain-mapping-version-section{
    width:30% !important;
    margin-top: 50px;
    margin-bottom: 30px;
    padding-top:20px;
    padding-left: 10px;
}
.table thead th {
    vertical-align: top;
    text-align: center;
    white-space: nowrap;
    position: sticky; top: -1px; z-index: 1;
    background-color: #e4e5e6;
}
.stickycol{
    position:sticky;
    left:0px;
    background-color: #e4e5e6;
    width:25%;
}
.domain-mapping-version-section .version-style {
    padding: 0 10px 10px 10px;
    border-bottom: 1px #ccc solid;
    margin-bottom: 10px;
    justify-content: left;
    }
    
    .domain-mapping-version-section .version-style:last-child {
    padding: 0 10px 0px 10px;
    border-bottom: 0px #ccc solid;
    margin-bottom: 0px;
    }
    
    .domain-mapping-version-section .version-style.text-center a {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #083ac8;
    }
    .domain-mapping-version-section .version-style p.date-txt {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.17px;
        color: #333333;
        margin: 0 0 1px 0;
        }
.domain-mapping-version-section .version-style p span.fontbold{
    font-weight: 600;
}
.ml-10 {
margin-left:10px !important;
}
.mb-10 {
margin-bottom:20px !important;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #ffffff !important;
    opacity: 1;
}

.versionActive p.date-txt{
    font-weight: bold !important;
}

input[type=checkbox], input[type=radio]{
    width: 14px;
    height: 14px;
    accent-color: #007bff;
}

input[type=checkbox]:checked{
    accent-color: #007bff;
}

.fa, .far, .fas {
    font-family: "Font Awesome 5 Free";
}
.far {
    font-weight: 400;
}
.fa, .fab, .fal, .far, .fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff !important;
    background-color: #828385 !important;
    border-color: #bfc1c3 !important;
    cursor: pointer;
}

