.eightflexbox{
    width:155px !important
}
.h-32-eight{
    height: 32px !important;
    margin-bottom: 2px !important;
}
.h-68-eight{
    height: 68px !important;
}
.lineclamp2{
    -webkit-line-clamp:2 !important;
}